// Imports

import React from "react"
import { graphql } from "gatsby";

import SEO from "../components/seo"
import Layout from "../components/layout"
import { ServicesHook } from '../hooks/services'
import ServiceCard from "../components/card-service/card-service"
import servicesPageStyles from "../styles/pages/services.module.css"

// Services

const ServicesPage = ({ data }) => {
  
  const services = ServicesHook().sort((a,b) => a.node.childMarkdownRemark.frontmatter.order - b.node.childMarkdownRemark.frontmatter.order );
  const pageData = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={servicesPageStyles.hero}>
        <div className={servicesPageStyles.callToAction}>
          <h3 className={servicesPageStyles.title}>{pageData.heroTitle}</h3>
          <p className={servicesPageStyles.details}>{pageData.heroSubtitle}</p>
        </div>
      </div>
      <div className={servicesPageStyles.servicesCards}>
        <div className={servicesPageStyles.servicesCardsContainer}>
          <div className={servicesPageStyles.servicesCardsSubContainer}>
            {services.map(service => (
              <>
                <ServiceCard service={service.node} newPage={false} tag={service.node.id} key={service.node.id} />
              </>
            ))}
          </div>
        </div>
      </div>
      <div className={servicesPageStyles.services}>
        <div className={servicesPageStyles.widthContainer}>
          <h5 className={servicesPageStyles.servicesTests__title}>{pageData.servicesTitle}</h5>
          <p className={servicesPageStyles.servicesTests__subtitle}>{pageData.servicesSubtitle}</p>
          <div className={servicesPageStyles.servicesTests}>
            <div className={servicesPageStyles.serviceTests}>
              {services.map(service => {
                const serviceData = service.node.childMarkdownRemark.frontmatter
                return (
                  <>
                    <h6 className={servicesPageStyles.serviceTests__title} name={service.node.id}>{serviceData.title}</h6>
                    <div className={servicesPageStyles.tests}>
                      {serviceData.tests.map(test => (
                        <p className={servicesPageStyles.test}>• {test}</p>
                      ))}
                    </div>
                  </>
                )}
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "services-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              heroTitle
              heroSubtitle
              heroButtonTitle
              servicesTitle
              servicesSubtitle
          }
        }
      }
    }
  }
}`